<template>
  <onboarding-scaffold>
    <template v-slot:content>
      <NotificationToast
        v-if="notification"
        :notification="notification"
        @resetNotification="notification = null"
      />
      <div class="w-full flex items-center justify-between">
        <div
          class="
            xl:w-1/2
            lg:w-full
            md:w-full
            w-full
            min-h-screen
            py-20
            xl:px-28
            lg:px-0
            md:px-0
            px-0
          "
        >
          <form-scaffold
            :button-text="'Create account'"
            :loading="submitting"
            @sendAction="chooseVerification"
          >
            <span slot="heading-text">Welcome to LegendPay!</span>
            <div slot="field-instructions">
              <span class="text-legend-gray dark:text-border-color mr-5"
                >Already have an account?</span
              >
              <router-link
                :to="{ name: 'Login' }"
                class="
                  text-primary-color
                  border-b border-primary-color
                  font-bold
                "
                >Log in</router-link
              >
            </div>
            <div slot="input-fields">
              <div>
                <CustomInput
                  @input-value="setValue"
                  :fields="{
                    label: 'First name',
                    fieldName: 'first_name',
                    placeholder: 'e.g Peter',
                  }"
                />
                <ValidationMessage v-if="loading && !$v.first_name.required">
                  <span slot="message">First name is required.</span>
                </ValidationMessage>
              </div>
              <div>
                <CustomInput
                  @input-value="setValue"
                  :fields="{
                    label: 'Last name',
                    fieldName: 'last_name',
                    placeholder: 'e.g Rabbit',
                  }"
                />
                <ValidationMessage v-if="loading && !$v.last_name.required">
                  <span slot="message">Last name is required.</span>
                </ValidationMessage>
              </div>
              <div>
                <CustomInput
                  :fields="{
                    label: 'Email address',
                    fieldName: 'email_address',
                    placeholder: 'e.g peter@legend.ng',
                  }"
                  @input-value="setValue"
                />
                <ValidationMessage v-if="loading && !$v.email_address.required">
                  <span slot="message"> Email address is required.</span>
                </ValidationMessage>
                <ValidationMessage v-if="loading && !$v.email_address.email">
                  <span slot="message">Enter a valid email address.</span>
                </ValidationMessage>
              </div>
              <div class="mt-6">
                <label
                  class="
                    text-legend-gray
                    dark:text-border-color
                    font-semibold
                    block
                    leading-relaxed
                  "
                  >Username</label
                ><input
                  v-debounce:1000ms="validateUsername"
                  v-model="username"
                  type="text"
                  placeholder="e.g peter_rabbit"
                  class="
                    text-seconadry-border
                    border border-border-color
                    bg-transparent
                    rounded-lg
                    w-full
                    p-3
                    focus:outline-none
                  "
                />
                <div class="username-validation text-sm py-1">
                  <span
                    v-if="validatingUser && !responseMessage"
                    class="text-legend-gray"
                    >Checking username...</span
                  >
                  <span v-else>{{ responseMessage }}</span>
                </div>
                <ValidationMessage v-if="loading && !$v.username.required">
                  <span slot="message">Username is required.</span>
                </ValidationMessage>
              </div>
              <div class="flex items-center relative mt-6">
                <div class="lg:w-1/3 xl:w-1/3 md:w-2/5 w-2/5 relative">
                  <div class="w-full relative">
                    <label
                      class="
                        text-legend-gray
                        dark:text-border-color
                        font-semibold
                        block
                        leading-relaxed
                      "
                      >Country code</label
                    >
                    <div
                      class="
                        flex
                        items-center
                        w-full
                        border border-border-color
                        rounded-lg
                        py-3
                        px-1
                      "
                    >
                      <img
                        :src="selectedCountryFlag"
                        alt=""
                        class="w-8 rounded shadow-md"
                      />
                      <vue-autosuggest
                        v-model="query"
                        :suggestions="filteredOptions"
                        @click="clickHandler"
                        @selected="onSelected"
                        :get-suggestion-value="getSuggestionValue"
                        :input-props="{
                          id: 'autosuggest__input',
                          placeholder: '+234',
                        }"
                      >
                        <div
                          slot-scope="{ suggestion }"
                          style="display: flex; align-items: center"
                        >
                          <img
                            :style="{
                              display: 'flex',
                              width: '25px',
                              height: '25px',
                              borderRadius: '15px',
                              marginRight: '10px',
                            }"
                            :src="suggestion.item.href.flag"
                          />
                          <div style="{ display: 'flex', color: 'navyblue'}">
                            {{ suggestion.item.name }}
                          </div>
                        </div>
                      </vue-autosuggest>
                    </div>
                  </div>
                  <img
                    :src="getImgUrl()"
                    alt="select country dialling code"
                    class="w-3 absolute right-4 top-11"
                  />
                </div>
                <div class="w-2/3 lg:ml-6 xl:ml-6 md:ml-5 ml-3">
                  <label
                    class="
                      text-legend-gray
                      dark:text-border-color
                      font-semibold
                      block
                      leading-relaxed
                    "
                    >Phone number</label
                  >
                  <input
                    v-model.number.trim="phone_number"
                    placeholder="e.g 8160973197"
                    class="
                      text-seconadry-border
                      bg-transparent
                      border border-border-color
                      rounded-lg
                      w-full
                      p-3
                      focus:outline-none
                    "
                  />
                </div>

                <ValidationMessage
                  v-if="loading && !$v.phone_number.required"
                  class="absolute left-40 -bottom-8"
                >
                  <span slot="message">Phone number is required.</span>
                </ValidationMessage>
              </div>
              <div class="mt-6">
                <label
                  class="
                    text-legend-gray
                    dark:text-border-color
                    font-semibold
                    block
                    leading-relaxed
                  "
                  >Referral ID (Optional)</label
                >
                <input
                  v-model.trim="referer_id"
                  type="text"
                  placeholder="e.g legend001"
                  class="
                    referer
                    text-seconadry-border
                    border border-border-color
                    bg-transparent
                    rounded-lg
                    w-full
                    p-3
                    focus:outline-none
                  "
                />
              </div>
            </div>
          </form-scaffold>
        </div>
        <div
          class="
            lg:hidden
            xl:inline-flex
            md:hidden
            hidden
            w-1/2
            h-screen
            fixed
            right-0
            top-0
            pr-28
            text-center
          "
        >
          <div class="relative flex flex-col items-center justify-center">
            <img
              src="@/assets/images/app-preview.svg"
              alt="Create account on legend pay"
              class="-mt-20"
            />
            <div class="w-full absolute bottom-20">
              <h2
                class="text-2xl font-bold leading-loose dark:text-border-color"
              >
                Seamless Internet Top up
              </h2>
              <p class="text-legend-gray dark:text-border-color">
                Smooth and seamless internet top for your fiber service<br />
                and Legend zones.
              </p>
            </div>
          </div>
        </div>
      </div>
      <section
        v-if="chooseVerifyMode"
        class="
          w-screen
          h-screen
          bg-legend-black bg-opacity-30
          flex
          items-center
          justify-center
          fixed
          top-0
          left-0
        "
      >
        <div
          class="
            bg-white
            dark:bg-dark-mode-primary
            xl:w-1/3
            lg:w-1/2
            md:w-1/2
            w-11/12
            py-10
            xl:px-12
            lg:px-12
            md:px-8
            px-4
            rounded-lg
            text-legend-gray
            dark:text-border-color
          "
        >
          <h1 class="text-2xl font-bold text-legend-black dark:text-white">
            Verification mode
          </h1>
          <div class="mt-8 mb-5 font-semibold">
            How do you want to receive your verification code?
          </div>
          <div
            class="
              flex
              justify-between
              text-legend-gray
              dark:text-border-color
              uppercase
              mb-16
            "
          >
            <div
              class="
                w-1/2
                p-5
                mr-5
                rounded-lg
                shadow-lg
                bg-white
                dark:bg-transparent
                flex
                items-center
                border
                cursor-pointer
              "
              :class="{ 'border-primary-color': isSMS }"
              @click="chooseSMS"
            >
              <img
                v-if="!isSMS"
                src="@/assets/images/checkbox-unchecked.svg"
                alt="via Phone number"
              />
              <div
                v-else
                style="width: 18px; height: 18px"
                class="
                  bg-primary-color
                  rounded
                  flex
                  items-center
                  justify-center
                "
              >
                <img
                  src="@/assets/images/check-mark.svg"
                  alt="via email address"
                />
              </div>
              <span class="font-bold text-xs ml-3.5">Phone number</span>
            </div>
            <div
              class="
                w-1/2
                p-5
                rounded-lg
                shadow-lg
                bg-white
                dark:bg-transparent
                flex
                items-center
                border
                cursor-pointer
              "
              :class="{ 'border-primary-color': isEmail }"
              @click="chooseEmail"
            >
              <img
                v-if="!isEmail"
                src="@/assets/images/checkbox-unchecked.svg"
                alt="via Phone number"
              />
              <div
                v-else
                style="width: 18px; height: 18px"
                class="
                  bg-primary-color
                  rounded
                  flex
                  items-center
                  justify-center
                "
              >
                <img
                  src="@/assets/images/check-mark.svg"
                  alt="via email address"
                />
              </div>
              <span class="font-bold text-xs ml-3.5">email address</span>
            </div>
          </div>
          <custom-button :loading="submitting" @sendAction="send">
            <span slot="button-text">Send Code</span>
          </custom-button>
        </div>
      </section>
    </template>
  </onboarding-scaffold>
</template>

<script>
import OnboardingScaffold from '@/components/OnboardingScaffold.vue'
import FormScaffold from '@/components/FormScaffold.vue'
import CustomInput from '@/components/OnboardingInput.vue'
import listOfCountries from '@/util/countries'
import { VueAutosuggest } from 'vue-autosuggest'
import { required, email } from 'vuelidate/lib/validators'
import { mapGetters } from 'vuex'

export default {
  name: 'Regsiter',
  components: {
    OnboardingScaffold,
    FormScaffold,
    CustomInput,
    VueAutosuggest,
  },
  mounted() {
    if (Object.keys(this.$route.query).includes('referral_id')) {
      this.referer_id = this.$route.query.referral_id

      const node = document.querySelector('.referer')
      node.setAttribute('readonly', true)
    }
  },
  data() {
    return {
      selectCountryCode: false,
      chooseVerifyMode: false,
      isEmail: false,
      isSMS: false,
      notification: null,
      query: '',
      selected: '+234',
      selectedCountryFlag:
        'https://restfulcountries.com/assets/images/flags/Nigeria.png',
      suggestions: listOfCountries,
      first_name: null,
      last_name: null,
      email_address: null,
      username: null,
      phone_number: null,
      responseMessage: null,
      referer_id: null,
      verification_mode: null,
      loading: false,
      submitting: false,
      validatingUser: false,
    }
  },
  validations() {
    return {
      first_name: { required },
      last_name: { required },
      email_address: { required, email },
      username: { required },
      phone_number: { required },
    }
  },
  computed: {
    ...mapGetters(['mode']),
    filteredOptions() {
      return [
        {
          data: this.suggestions.filter((option) => {
            return (
              option.name.toLowerCase().indexOf(this.query.toLowerCase()) > -1
            )
          }),
        },
      ]
    },
  },
  methods: {
    getImgUrl() {
      const imagePath =
        this.mode === 'dark' ? 'chevron-down-white.svg' : 'chevron-down.svg'
      return require(`@/assets/images/${imagePath}`)
    },
    chooseEmail() {
      this.isEmail = true
      this.isSMS = false
      this.verification_mode = 'email'
    },
    chooseSMS() {
      this.isEmail = false
      this.isSMS = true
      this.verification_mode = 'sms'
    },
    clickHandler() {
      this.selected = null
    },
    onSelected(item) {
      this.selected = item.item.phone_code
      this.selectedCountryFlag = item.item.href.flag
    },
    getSuggestionValue(suggestion) {
      return suggestion.item.phone_code.charAt(0) === '+'
        ? suggestion.item.phone_code
        : `+${suggestion.item.phone_code}`
    },
    setValue(value) {
      const currentField = value.field
      this[currentField] = value.value
    },
    chooseVerification() {
      if (this.submitting) {
        return
      }

      this.loading = true

      this.$v.$touch()

      if (this.$v.$invalid) {
        return
      }

      this.chooseVerifyMode = true
      this.loading = false
    },
    async send() {
      try {
        if (this.submitting) {
          return
        }

        if (!this.verification_mode) {
          this.notification = {
            type: 'error',
            message: 'Choose verificaton mode.',
          }

          return
        }

        this.chooseVerifyMode = false

        this.submitting = true
        const { status, data } = await this.axios.post('/register', {
          first_name: this.first_name,
          last_name: this.last_name,
          username: this.username,
          email_address: this.email_address,
          country_code: this.selected,
          phone_number: this.phone_number.toString(),
          referrer_id: this.referer_id,
          verification_mode: this.verification_mode,
        })

        if (status >= 200 && status < 300) {
          this.$store.commit('SET_USERNAME', data.data.username)
          this.$router.push({
            name: 'Verify',
            query: { verification_mode: this.verification_mode },
          })
        }
      } catch (error) {
        if (error.response.status === 400) {
          this.responseMessage = error.response.data.message
        } else if (error.response.status === 422) {
          this.notification = {
            type: 'error',
            message: error.response.data.errors.email_address[0],
          }
        }
      }
    },
    async validateUsername() {
      try {
        if (!this.username.length) {
          return
        }

        this.validatingUser = true
        this.responseMessage = null
        const { status, data } = await this.axios.post(
          '/register/username/validate',
          {
            username: this.username,
          }
        )

        if (status === 200) {
          const node = document.querySelector('.username-validation')
          this.responseMessage = `${data.message} `
          this.validatingUser = false
          node.classList.remove('text-primary-red')
          node.classList.add('text-secondary-green')
        }
      } catch (error) {
        if (error.response.status === 400) {
          const node = document.querySelector('.username-validation')
          this.responseMessage = error.response.data.message
          node.classList.remove('text-secondary-green')
          node.classList.add('text-primary-red')
        }
        this.validatingUser = false
      }
    },
  },
}
</script>
